import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';

if (env.PUBLIC_SENTRY_DSN) {
	let stage = env.PUBLIC_STAGE;
	if (stage) {
		stage = stage.toUpperCase();
	} else {
		stage = 'local';
	}
	Sentry.init({
		dsn: env.PUBLIC_SENTRY_DSN,
		environment: stage,
		release: env.PUBLIC_RELEASE,

		integrations: [
			Sentry.replayIntegration({
				// Please note that updating worker file requires also updating the version in the file name as otherwise it will load old cached version
				workerUrl: '/sentry-replay-worker-8-3-11.min.js'
			})
		],

		// The docs (https://docs.sentry.io/platforms/javascript/guides/sveltekit/manual-setup/)
		// recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 0.5,
		// This enables session replay in Sentry
		// The only need as of now is to record issues therefore only errors are recorded
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0
	});
}

export const handleError = Sentry.handleErrorWithSentry();
