import type { ParamMatcher } from '@sveltejs/kit';
import { PRODUCTS_KEYWORD_TRANSLATIONS } from '$lib/consts';

/**
 * A matcher to verify if param contains 'product' in name in any of possible language translations. This is applicable for legacy URLs with shareclass details
 * Legacy URL example: https://invest.swisslife-am.com/en/products/CH0293784861-swiss-life-ref-ch--esg-swiss-properties
 * @param param the param to check
 */

export const match: ParamMatcher = (param: string): boolean =>
	Object.values(PRODUCTS_KEYWORD_TRANSLATIONS).includes(param);
