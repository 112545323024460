import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0];

export const dictionary = {
		"/(portal)": [~4,[2]],
		"/auth_successful": [~13],
		"/(portal)/compare": [5,[2]],
		"/(portal)/detail/[fund_id=sfid]/[[share_class_id=sfid]]/experience": [7,[3]],
		"/(portal)/detail/[fund_id=sfid]/[[share_class_id=sfid]]/experience/show": [8,[3]],
		"/(portal)/detail/[fund_id=sfid]/[[share_class_id=sfid]]": [~6,[2]],
		"/(portal)/fund-experience/old": [9,[2]],
		"/(portal)/fund-structure-v2": [10,[2]],
		"/(portal)/portfolio": [11,[2]],
		"/[lang=lang]/[products=products]/[isin=isin]": [~12]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';