import type { ParamMatcher } from '@sveltejs/kit';

/**
 * A matcher to test ISIN param (2 characters followed by 10 digits and characters).
 * ISIN can also consist of hyphens but based on URL examples it's assumed that variant without hyphens is only used in URL.
 * Two first characters has to be letters, last character has to be digit. All in between consist of letters and digits.
 * Whole URL contains also fund name afterwards and within this check we only focus on ISIN as based on that redirect will take place if exists
 * Regex has been checked against ReDOS vulnerability and is considered to be safe (https://devina.io/redos-checker)
 * @param param the param to check
 */
export const match: ParamMatcher = (param: string): boolean =>
	/^(?:[A-Z]{2}[A-Z0-9]{9}\d)/.test(param);
