import type { ParamMatcher } from '@sveltejs/kit';
import { LANGUAGES } from '$lib/consts';

/**
 * A matcher to verify if param is one of supported languages. Language appears in legacy URLs with shareclass details
 * Legacy URL example: https://invest.swisslife-am.com/en/products/CH0293784861-swiss-life-ref-ch--esg-swiss-properties
 * @param param the param to check
 */

export const match: ParamMatcher = (param: string): boolean => LANGUAGES.includes(param);
