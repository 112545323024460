import { SALESFORCE_ID_PATTERN } from '$lib/consts';
import type { ParamMatcher } from '@sveltejs/kit';

/**
 * A matcher to test salesforce ID parameters (15 or 18 alphanumeric characters).
 * This doesn't do a deeper validation check than character type and param length, so it doesn't *guarantee*
 * to be a valid salesforce ID.
 * Regex has been checked against ReDOS vulnerability and is considered to be safe (https://devina.io/redos-checker)
 * @param param the param to check
 */
export const match: ParamMatcher = (param: string): boolean => SALESFORCE_ID_PATTERN.test(param);
